const ParashiftRoles = {
  admin: $localize `:@@common.admin:Admin` as 'admin',
  billing: $localize `:@@common.billing:Billing` as 'billing',
  employee: $localize `:@@common.employer:Employee` as 'employee',
  engineering: $localize `:@@common.employer:Engineering` as 'engineering',
  sales: $localize `:@@common.sales:Sales` as 'sales',
  support: $localize `:@@common.support:Support` as 'support'
};
type ParashiftRoles = (typeof ParashiftRoles)[keyof typeof ParashiftRoles];
export { ParashiftRoles };
